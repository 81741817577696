import { IntlProvider, useIntl } from 'react-intl';
import { useEffect } from 'react';
import en_messages from 'locales/en.json';

const useSetupWindowUtils = () => {
  const intl = useIntl();
  useEffect(() => {
    const getFormattedMessageInEnglish = ({ id, values }) => {
      const englishIntlProvider = new IntlProvider(
        {
          locale: 'en',
          messages: en_messages,
        },
        {},
      );

      const { intl: englishIntl } = englishIntlProvider?.state;

      return englishIntl.formatMessage({ id }, values);
    };

    window.app = {
      ...(window.app || {}),
      intl: {
        ...intl,
        getFormattedMessageInEnglish,
      },
    };
  }, [intl]);
};

export default useSetupWindowUtils;
