import { datadogRum } from '@datadog/browser-rum';

/**
 * Initialize Datadog RUM with the given configuration
 * @returns {void}
 */
export const initializeDatadogRum = () => {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: process.env.REACT_APP_DATADOG_SERVICE,
    env: process.env.REACT_APP_NODE_ENV,
    version: process.env.REACT_APP_VERSION || '1.0.0',
    sampleRate: 100,
    premiumSampleRate: parseInt(
      process.env.REACT_APP_DATADOG_PREMIUM_SAMPLE_RATE,
      10,
    ),
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    allowedTracingOrigins: [process.env.REACT_APP_API_URL],
    enableExperimentalFeatures: ['feature_flags'],
  });
};

/**
 * Start Datadog Session Replay Recording
 * @returns {void}
 */
export const startDatadogSessionReplayRecording = () => {
  datadogRum.startSessionReplayRecording();
};

/**
 * Set User metadata for Datadog
 * @param user
 * @returns {void}
 */
export const setUserMetadata = ({ sanitizedUser }) => {
  if (!sanitizedUser) {
    return;
  }

  let name;
  if (sanitizedUser.customer) {
    name = `${sanitizedUser.customer.firstName} ${sanitizedUser.customer.lastName}`;
  } else if (sanitizedUser.prospect) {
    name = `${sanitizedUser.prospect.firstName} ${sanitizedUser.prospect.lastName}`;
  } else {
    name = null;
  }
  datadogRum.setUser({
    id: sanitizedUser.id,
    name,
    email: sanitizedUser?.emailAddress ?? null,
    organizationId: sanitizedUser.organizationId,
    propertyId: sanitizedUser.propertyId,
    customerId: sanitizedUser.customerId ?? null,
    prospectId: sanitizedUser.prospectId ?? null,
    isActive: sanitizedUser.isActive,
    isClaimed: sanitizedUser.isClaimed,
    isVerified: sanitizedUser.isVerified,
    lastSystemAccess: sanitizedUser.lastSystemAccess,
    numberFailedLoginAttempts: sanitizedUser.numberFailedLoginAttempts,
  });
};

export const clearUserMetadata = () => {
  datadogRum.clearUser();
};

/**
 * Add a feature flag evaluation to Datadog
 * @param key
 * @param value
 */
export function addFeatureFlagEvaluation(key, value) {
  datadogRum.addFeatureFlagEvaluation(key, value);
}
