// @flow
import isEmpty from 'ramda.isempty';
import { matchPath } from 'react-router-dom';
import { getLocalURLData, setLocalURLData } from 'services/fusionAuthService';
import { INTENT_TYPES } from 'contexts/IntentContext';

const LOCAL_UNIT_INTENT_DATA = 'LOCAL_UNIT_INTENT_DATA';
const LOCAL_TELL_US_INTENT_DATA = 'LOCAL_TELL_US_INTENT_DATA';

const getLocalIntentData = (key) => {
  let intentState = null;
  let intentParams = null;

  const jsonURLData = sessionStorage.getItem(key) || localStorage.getItem(key);
  if (jsonURLData) {
    intentState = JSON.parse(jsonURLData) || {};
    intentParams = new URLSearchParams({
      state: jsonURLData,
    });
  }

  return { state: intentState, params: intentParams };
};
export const getLocalUnitIntentData = () =>
  getLocalIntentData(LOCAL_UNIT_INTENT_DATA);

export const getLocalTellMeIntentData = () =>
  getLocalIntentData(LOCAL_TELL_US_INTENT_DATA);

export const setLocalIntentData = (intentData) => {
  let key: string | null = null;
  let data = null;

  if (
    intentData?.type === INTENT_TYPES.ASSIGN_UNIT &&
    intentData?.unitId?.length
  ) {
    key = LOCAL_UNIT_INTENT_DATA;
    data = intentData;
  } else if (intentData?.type === INTENT_TYPES.TELL_US) {
    key = LOCAL_TELL_US_INTENT_DATA;
    data = intentData;
  }
  if (key && data) {
    localStorage.setItem(key, JSON.stringify(data));
    sessionStorage.setItem(key, JSON.stringify(data));
  }
};

export const deleteLocalUnitIntentData = () => {
  localStorage.removeItem(LOCAL_UNIT_INTENT_DATA);
  sessionStorage.removeItem(LOCAL_UNIT_INTENT_DATA);
};

export const deleteLocalTellMeIntentData = () => {
  localStorage.removeItem(LOCAL_TELL_US_INTENT_DATA);
  sessionStorage.removeItem(LOCAL_TELL_US_INTENT_DATA);
};

export const getOrgIdAndPropertyIdFromURL = () => {
  const { pathname, search, origin } = window.location;

  const match = matchPath(pathname, {
    path: '/:organizationId/:propertyId',
    exact: false,
    strict: false,
  });

  const matchIntent = matchPath(pathname, {
    path: '/:organizationId/:propertyId/intent/',
    exact: true,
    strict: false,
  });

  const matchVerify = matchPath(pathname, {
    path: '/verifyToken',
    exact: false,
    strict: false,
  });

  const ids = {
    organizationId: null,
    propertyId: null,
  };

  if ((match && !matchIntent) || (match && matchIntent?.isExact === false)) {
    ids.organizationId = match?.params?.organizationId ?? '';
    ids.propertyId = match?.params?.propertyId ?? '';
  } else if (matchVerify) {
    // gets ids from auth params state on verify
    const params = new URLSearchParams(search);
    const stateParams = params.get('state');
    const state = JSON.parse(stateParams);

    if (state?.organizationId?.length && state?.propertyId?.length) {
      ids.organizationId = state.organizationId;
      ids.propertyId = state.propertyId;
    }
  } else if (matchIntent?.isExact) {
    // gets intent data from params state
    const params = new URLSearchParams(search);
    const stateParams = params.get('state');
    const state = JSON.parse(stateParams);

    if (
      matchIntent?.params?.organizationId?.length &&
      matchIntent?.params?.propertyId?.length
    ) {
      ids.organizationId = matchIntent?.params?.organizationId ?? '';
      ids.propertyId = matchIntent?.params?.propertyId ?? '';
    }

    if (ids?.organizationId?.length && ids?.propertyId?.length) {
      setLocalIntentData({
        ...state,
        propertyId: ids.propertyId,
        organizationId: ids.organizationId,
      });
    }

    // clear [intent] search params from URL
    const replacedPathName = pathname.replace('/intent/', '');
    window.location.replace(`${origin}${replacedPathName}`);
  } else {
    const URLData = getLocalURLData();
    if (URLData?.organizationId?.length && URLData?.propertyId?.length) {
      ids.organizationId = URLData.organizationId;
      ids.propertyId = URLData.propertyId;
    }
  }

  return ids;
};

export const updateOrgIdAndPropertyIdFromURL = (): void => {
  const { organizationId, propertyId } = getOrgIdAndPropertyIdFromURL();

  if (organizationId?.length && propertyId?.length) {
    setLocalURLData({ organizationId, propertyId });
  }
};
/**
 * Retrieves value from URL for `name`.
 * @param {string} name
 * @returns Value or `null`
 */
export const getUrlParam = (name) => {
  const urlParams = new URLSearchParams(document.location.search.substring(1));
  return urlParams.get(name);
};

/**
 * Retrieves HEX Color from URL for `name`.
 * The `#` should be excluded.  For example, `#FF0000` would be `FF0000`.
 * @param {string} name
 * @returns HEX Color or `null`
 */
export const getUrlColor = (name) => {
  const colorParam = getUrlParam(name);
  return colorParam ? `#${colorParam}` : null;
};

export const numberParser = (number) =>
  !number ? null : number.replace(/[^0-9 ]/g, '');

/**
 * Uses browser built-in localization library to format number.
 * @param {string|number} number
 * @returns string
 */
export const formatCurrency = (number) =>
  Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(number);

/**
 * Adds dashes to a phone number or SSN.
 *
 * The extra complexity stems from the fact that our database has numbers with
 * dashes, without, and with extra junk.
 *
 * @param {string} number actual number to be formatted
 * @param {string} type 'phone' | 'ssn', indicates the format of the number
 * @param {string} sep seperator, defaults to `-`
 * @returns string
 */
export const formatNumber = (number, type, sep = '-') => {
  if (!number) {
    return '';
  }

  const parsedNumbers = number.match(/\d+/g).join([]);

  const numberTypes = {
    phone: {
      input: /^(\d{3})(\d{3})(\d{4})$/,
      output: (match) => `${match[1]}${sep}${match[2]}${sep}${match[3]}`,
    },
    ssn: {
      input: /^(\d{3})(\d{2})(\d{4})$/,
      output: (match) => `${match[1]}${sep}${match[2]}${sep}${match[3]}`,
    },
    feid: {
      input: /^(\d{2})(\d{7})$/,
      output: (match) => `${match[1]}${sep}${match[2]}`,
    },
  };

  /**
   * If it matches one of the preformatted types...
   */
  if (numberTypes[type]) {
    try {
      const { input, output } = numberTypes[type];
      return output(parsedNumbers.match(input));
    } catch (e) {
      console.warn(
        `[formatNumber] Unable to format ${number} into ${type}, using ${parsedNumbers}`,
      );
      return parsedNumbers;
    }
  }

  /**
   * Otherwise, return the value sans any extraneous characters...
   */
  return parsedNumbers;
};

/**
 * Given an array of objects, sorts by a given `prop`.
 * Really handy for options in a drop-down.
 * @param {Array} arr array of Objects to be sorted
 * @param {string} prop property or attribute to be sorted on
 * @param {boolean} isDesc whether or not to sort descending (default is ascending)
 * @returns {Array} sorted array
 */
export const sortByProp = (arr, prop, isDesc = false) =>
  arr.sort((a, b) => {
    let aValue = a[prop];
    let bValue = b[prop];

    if (typeof aValue === 'string' || typeof bValue === 'string') {
      aValue = aValue.toUpperCase();
      bValue = bValue.toUpperCase();
    }

    if (aValue < bValue) {
      return isDesc === true ? 1 : -1;
    }

    if (aValue > bValue) {
      return isDesc === true ? -1 : 1;
    }

    return 0;
  });

/**
 * Function that takes and object and returns an object that has all the
 * properties with null values removed
 * @param {object} objectToBeScrubbed
 */
export const nullScrubber = (objectToBeScrubbed) => {
  const nullFilter = (key) => objectToBeScrubbed[key] !== null;
  const nonNullFields = Object.keys(objectToBeScrubbed).filter(nullFilter);

  return nonNullFields.reduce(
    (scrubbedObject, key) => ({
      ...scrubbedObject,
      [key]: objectToBeScrubbed[key],
    }),
    {},
  );
};

/**
 * Function that takes and object and returns an object that has all the
 * properties with null or empty string values removed
 * @param {object} objectToBeScrubbed
 */
export const nullAndEmptyStringScrubber = (objectToBeScrubbed) => {
  const nullFilter = (key) => objectToBeScrubbed[key] !== null;
  const emptyStringFilter = (key) => objectToBeScrubbed[key] !== '';
  const nonEmptyFields = Object.keys(objectToBeScrubbed)
    .filter(nullFilter)
    .filter(emptyStringFilter);

  return nonEmptyFields.reduce(
    (scrubbedObject, key) => ({
      ...scrubbedObject,
      [key]: objectToBeScrubbed[key],
    }),
    {},
  );
};

/**
 * Capitalizes first letter of a string.
 * @param {*} string
 */
export const capitalizeFirstLetter = (string) =>
  `${string[0].toUpperCase()}${string.slice(1)}`;

/**
 * Returns a person's display name
 * @param {Object} person At a minimum, a person should have firstName and lastName
 */
export const parseDisplayName = (person) => {
  if (!person) {
    return null;
  }
  const { preferredName, firstName, lastName } = person;
  return preferredName
    ? `${preferredName} ${lastName}`
    : `${firstName} ${lastName}`;
};

/**
 * Returns a person's legal name
 * @param {Object} person At a minimum, a person should have firstName and lastName
 */
export const parseLegalName = (person) => {
  if (!person) {
    return null;
  }
  const { firstName, middleName, lastName } = person;
  return [firstName, middleName, lastName]
    .reduce(
      (fullName, namePart) => (namePart ? `${fullName}${namePart} ` : fullName),
      '',
    )
    .trim();
};

export const getYesNoRadioValue = (bool) => {
  switch (bool) {
    case true:
      return 'yes';
    case false:
      return 'no';
    default:
      return '';
  }
};

export const getStringBooleanFromYesNo = (string) => {
  switch (string) {
    case 'yes':
      return 'true';
    case 'no':
      return 'false';
    default:
      return null;
  }
};

export const getBooleanFromYesNo = (string) => {
  switch (string) {
    case 'yes':
      return true;
    case 'no':
      return false;
    default:
      return null;
  }
};

export const isNilOrEmpty = (thing) => {
  return !thing || isEmpty(thing);
};

/**
 * Transform a snake case string into a user friendly string
 * Examples:
 *  - 'pets' => 'Pets'
 *  - 'emergencyContacts' => 'Emergency Contacts'
 * @param  {} string
 */
export const snakeCaseToUserFriendly = (string) => {
  if (isNilOrEmpty(string)) {
    return '';
  }
  return string
    .replace(/([A-Z])/g, ' $1') // Space before upper case
    .replace(/^./, (str) => str.toUpperCase()); // Upper case first letter
};

export const removeNonNumericCharacters = (value: string): string =>
  value?.replace(/[\D]/g, '');

export const formatFileSize = (size) => {
  const i = Math.floor(Math.log(size) / Math.log(1024));
  return `${(size / Math.pow(1024, i)).toFixed(2) * 1} ${
    ['B', 'kB', 'MB', 'GB', 'TB'][i]
  }`;
};

export const deleteEmptyProperties = (obj) => {
  for (const key in obj) {
    if (
      obj[key] === null ||
      obj[key] === undefined ||
      (typeof obj[key] === 'string' && obj[key].trim() === '') ||
      (Array.isArray(obj[key]) && obj[key].length === 0)
    ) {
      delete obj[key];
    }
  }
  return obj;
};

export const getValueOrUndefined = (value): string | undefined =>
  value?.length ? value : undefined;
